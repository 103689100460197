<template>
  <div class="bvt_product_wrapper">
    <div class="product_header">
      <span>{{ $t("L_MAIN.TITLE_002") }}</span>
    </div>
    <div class="product_mian">
      <div
        class="left_menu"
        :style="
          $t('L_MAIN.TITLE_002') === '产品中心' ? 'width:234px' : 'width:316px'
        "
      >
        <div
          class="left_menu_list"
          v-for="item in menuList"
          :key="item.icon"
          @click="changeMenu(item)"
          :class="selectedMenuId === item.icon ? 'active' : ''"
        >
          <div
            class="divider"
            :class="selectedMenuId === item.icon ? 'active' : ''"
          ></div>
          <img :src="getStaticData(item.icon)" alt="" align="absmiddle"/>
          <span :class="selectedMenuId === item.icon ? 'active' : ''">{{
            item.label
          }}</span>
        </div>
      </div>
      <div class="right_content">
        <div
          class="project_item"
          v-for="item in projectListbyMenu"
          :key="item.id"
        >
          <div class="project_img">
            <img :src="getStaticData(item.detail.tag)" alt="" />
          </div>
          <div class="project_text">
            <div class="project_text_top">
              <div class="project_name">{{ item.title }}</div>
              <div class="project_btn">
                <a
                  class="btnText btnBg2"
                  :href="item.detail.link + '?tag=' + item.detail.tag"
                >
                  {{ item.detail.label }}
                </a>
                <a
                  class="btnText2 btnBg1"
                  :href="item.tryout.link"
                  :class="item.tryout.class"
                  :style="
                    $t('L_MAIN.TITLE_002') === '产品中心'
                      ? 'width:83px'
                      : 'width:100px'
                  "
                  v-if="item.tryout.class === 'no'"
                  @click="handleShowTip"
                >
                  {{ item.tryout.label }}
                </a>
                <a
                  class="btnText btnBg2"
                  :href="item.tryout.link"
                  :target="item.tryout.isTarget ? '_blank' : '_self'"
                  :class="item.tryout.class"
                  @click.prevent="linkClick(item.tryout.link, item.detail.tag)"
                  v-else-if="item.tryout.class === 'use'"
                >
                  {{ item.tryout.label }}
                </a>
                <a
                  class="btnText btnBg2"
                  :href="item.tryout.link"
                  :target="item.tryout.isTarget ? '_blank' : '_self'"
                  :class="item.tryout.class"
                  @click.prevent="linkClick(item.tryout.link, item.detail.tag)"
                  v-else
                >
                  {{ item.tryout.label }}
                </a>
              </div>
            </div>
            <div class="project_desc">
              <span>{{ item.desc }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="400"
      :before-close="handleClose"
    >
      <p>{{ $t("L_MAIN.BUTTON_0006") }}</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">
          {{ $t("L_SYSTEM_TIP.TIP_0002") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { loginByPhone, loginByName } from "@/api/building";
import { homePageMenu } from "@/api/login";
import { loginNoCaptcha } from "@/api/fxpc";
export default {
  name: "product",
  data() {
    return {
      menuList: [
        {
          name: this.$t("L_MAIN.MENU_001"),
          label: "基础数据服务",
          icon: "jcsjfw",
        },
        {
          name: this.$t("L_MAIN.MENU_009"),
          label: "雷达信息知识一张图",
          icon: "ldxxzsyzt",
        },
        { name: this.$t("L_MAIN.MENU_002"), label: "应急管理", icon: "yjgl" },
        { name: this.$t("L_MAIN.MENU_003"), label: "城市建筑", icon: "csjz" },
        { name: this.$t("L_MAIN.MENU_004"), label: "水利设施", icon: "slss" },
        { name: this.$t("L_MAIN.MENU_005"), label: "矿山安全", icon: "ksaq" },
        { name: this.$t("L_MAIN.MENU_007"), label: "综合遥感", icon: "zhyg" },
        { name: this.$t("L_MAIN.MENU_008"), label: "专题事件", icon: "ztsj" },
        { name: this.$t("L_MAIN.MENU_010"), label: "能源管理", icon: "nygl" },
        // {  name: this.$t("L_MAIN.MENU_006"), label: '农业遥感', icon: 'nyyg' },
      ],
      selectedMenuId: 0,
      productList: [
        // {
        //   // InSAR数据服务
        //   id: 1,
        //   imgURl: "",
        //   title: this.$t("L_MAIN.PRODUCT_0001"),
        //   desc: this.$t("L_MAIN.PRODUCT_0002"),
        //   detail: {
        //     link: "#/product/insar",
        //     tag: "insar",
        //     label: this.$t("L_MAIN.BUTTON_0001"),
        //   },
        //   tryout: {
        //     link: "",
        //     label: this.$t("L_MAIN.BUTTON_0003"),
        //     isTarget: true,
        //     class: "",
        //   },
        // },
        {
          // 全国InSAR形变一张图
          id: 2,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0042"),
          desc: this.$t("L_MAIN.PRODUCT_0042_01"),
          detail: {
            link: "#/product/insar",
            tag: "insar2",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 城市建筑形变风险智能排查
          id: 3,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0022"),
          desc: this.$t("L_MAIN.PRODUCT_0023"),
          detail: {
            link: "#/index",
            tag: "building",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 一带一路国家地表形变一张图
          id: 4,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0072"),
          desc: this.$t("L_MAIN.PRODUCT_0072_01"),
          detail: {
            link: "#/index",
            tag: "ydyl",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 建筑风险快速识别与监测系统
          id: 5,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0071"),
          desc: this.$t("L_MAIN.PRODUCT_0071_01"),
          detail: {
            link: "#/index",
            tag: "building2",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 遥感应急大数据平台
          id: 6,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0003_01"),
          desc: this.$t("L_MAIN.PRODUCT_0004_01"),
          detail: {
            link: "#/index",
            tag: "emergency",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 矿区空天地孔协同监测平台
          id: 7,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0031"),
          desc: this.$t("L_MAIN.PRODUCT_0031_01"),
          detail: {
            link: "#/index",
            tag: "kuangqu",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 风险普查
          id: 8,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0032"),
          desc: this.$t("L_MAIN.PRODUCT_0032_01"),
          detail: {
            link: "#/index",
            tag: "census",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 矿山采空区沉降AI智能识别与动态监测
          id: 9,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0061"),
          desc: this.$t("L_MAIN.PRODUCT_0061_01"),
          detail: {
            link: "#/index",
            tag: "ordos",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 综合遥感监测云服务平台
          id: 10,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0051"),
          desc: this.$t("L_MAIN.PRODUCT_0051_01"),
          detail: {
            link: "#/index",
            tag: "rs",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 专题事件集
          id: 11,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0052"),
          desc: this.$t("L_MAIN.PRODUCT_0052_01"),
          detail: {
            link: "#/index",
            tag: "zt",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 中小型水库安全监测系统
          id: 12,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0062"),
          desc: this.$t("L_MAIN.PRODUCT_0062_01"),
          detail: {
            link: "#/index",
            tag: "dam",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 数字农场管理平台
          id: 13,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0081"),
          desc: this.$t("L_MAIN.PRODUCT_0081_01"),
          detail: {
            link: "#/index",
            tag: "farm",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 耕地态势监测系统
          id: 14,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0082"),
          desc: this.$t("L_MAIN.PRODUCT_0082_01"),
          detail: {
            link: "#/index",
            tag: "farmland",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 海南雷达信息知识产品一张图
          id: 15,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0083"),
          desc: this.$t("L_MAIN.PRODUCT_0083_01"),
          detail: {
            link: "#/index",
            tag: "hainan",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 广东雷达信息知识产品一张图
          id: 16,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0084"),
          desc: this.$t("L_MAIN.PRODUCT_0084_01"),
          detail: {
            link: "#/index",
            tag: "gd",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 雄安雷达信息知识产品一张图
          id: 17,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0085"),
          desc: this.$t("L_MAIN.PRODUCT_0085_01"),
          detail: {
            link: "#/index",
            tag: "xiongan",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 烟台雷达信息知识产品一张图
          id: 18,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0086"),
          desc: this.$t("L_MAIN.PRODUCT_0086_01"),
          detail: {
            link: "#/index",
            tag: "yantai-web",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 澳门雷达信息知识产品一张图
          id: 20,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0088"),
          desc: this.$t("L_MAIN.PRODUCT_0088_01"),
          detail: {
            link: "#/index",
            tag: "macau",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
        {
          // 伊金霍洛旗能源一张图
          id: 19,
          imgURl: "",
          title: this.$t("L_MAIN.PRODUCT_0087"),
          desc: this.$t("L_MAIN.PRODUCT_0087_01"),
          detail: {
            link: "#/index",
            tag: "yjhlq",
            label: this.$t("L_MAIN.BUTTON_0001"),
          },
          tryout: {
            link: "",
            label: this.$t("L_MAIN.BUTTON_0003"),
            isTarget: true,
            class: "",
          },
        },
      ],
      projectListbyMenu: [],
      dialogTitle: this.$t("L_SYSTEM_TIP.TIP_0001"),
      dialogButton: this.$t("L_SYSTEM_TIP.TIP_0002"),
      dialogVisible: false,
    };
  },
  mounted() {
    this.handleExcuteTryout();
  },
  computed: {
    userInfo() {
      return this.$store.state.system.userInfo;
    },
    token() {
      return this.$store.state.system.token;
    },
    loginStatus() {
      return this.$store.state.system.isLogin;
    },
    language() {
      let language = this.$i18n.locale;
      return language;
    },
  },
  watch: {
    userInfo(val) {
      this.handleExcuteTryout();
    },
  },
  methods: {
    getStaticData(name) {
      return require(`@/assets/image/pc/index/product/${name}.png`);
    },
    async handleExcuteTryout() {
      this.productList.forEach((item) => {
        item.imgURl = this.getStaticData(item.detail.tag);
        item.tryout.label = this.$t("L_MAIN.BUTTON_0003"); //退出登陆的复位
        item.tryout.class = "";
      });
      let building_token = null;
      let larkview_token = null;
      let larkview_eventid = null;
      if (this.loginStatus) {
        // 获取城市建筑形变风险智能化排查系统token---start
        let userLogin = window.localStorage.userLogin;
        if (userLogin && userLogin !== "" && userLogin !== null) {
          let obj = JSON.parse(userLogin);
          if (obj.phone) {
            // ---手机号登录
            await loginByPhone(obj.phone).then((res) => {
              if (res.code === 200) {
                building_token = res.token;
              }
            });
          } else if (obj.username && obj.password) {
            // ---密码登录
            let param = {
              username: obj.username,
              password: obj.password,
            };
            await loginByName(param).then((res) => {
              if (res.code === 200) {
                building_token = res.token;
              }
            });
          }
        }
        // 获取城市建筑形变风险智能化排查系统token---end
        larkview_token = this.token;
        if (this.userInfo !== "" && this.userInfo.eventid) {
          larkview_eventid = this.userInfo.eventid;
        }
      }
      let param = "";
      if (this.language === "en") {
        param += "language=1";
      } else {
        param += "language=0";
      }
      setTimeout(() => {
        if (larkview_eventid !== null) {
          param += "&usereventid=" + larkview_eventid;
        }
        homePageMenu(param)
          .then((res) => {
            if (res.code === 200) {
              this.menuList = res.data;
              this.productList = [];
              this.menuList.map((el) => {
                if (el.icon === "jcsjfw") {
                  el.id = 0;
                } else if (el.icon === "ldxxzsyzt") {
                  el.id = 1;
                } else if (el.icon === "yjgl") {
                  el.id = 2;
                } else if (el.icon === "csjz") {
                  el.id = 3;
                } else if (el.icon === "slss") {
                  el.id = 4;
                } else if (el.icon === "ksaq") {
                  el.id = 5;
                } else if (el.icon === "zhyg") {
                  el.id = 6;
                } else if (el.icon === "ztsj") {
                  el.id = 7;
                } else if (el.icon === "nygl") {
                  el.id = 8;
                }
                el.function.forEach(async (p) => {
                  p.detail.label = this.$t("L_MAIN.BUTTON_0001");
                  p.tryout = {
                    link: "",
                    label: this.$t("L_MAIN.BUTTON_0003"),
                    isTarget: true,
                    class: "",
                  };
                  if (p.status === 1 || p.status === 2) {
                    if (p.url.includes("token")) {
                      if (p.detail.tag === "census") {
                        let { token } = await loginNoCaptcha({
                          username: "admin",
                          password: "Qdxhahbj@0000",
                        });
                        p.url = p.url + token;
                      } else if (p.detail.tag === "insar2") {
                        if (
                          building_token === null ||
                          larkview_token === null
                        ) {
                          p.url =
                              "#/login?type=2";
                        } else {
                          p.url =
                            p.url +
                            larkview_token +
                            "&type=larkview&buildingToken=" +
                            building_token;
                        }
                      } else if (p.detail.tag === "building") {
                        if (building_token === null) {
                          p.url =
                              "#/login?type=2";
                        } else {
                          p.url = p.url + building_token;
                        }
                      } else if (p.detail.tag === "rs") {
                        if (
                          building_token === null ||
                          larkview_token === null
                        ) {
                          p.url =
                              "#/login?type=2";
                        } else {
                          p.url = p.url + larkview_token + "&type=2";
                        }
                      } else if (p.detail.tag === "ydyl") {
                        if (larkview_token === null) {
                          p.url =
                              "#/login?type=2";
                        } else {
                          p.url = p.url + larkview_token + "&type=login";
                        }
                      } else {
                        if (larkview_token === null) {
                          p.url =
                              "#/login?type=2";
                        } else {
                          p.url = p.url + larkview_token;
                        }
                      }
                    }
                    this.productList.push(p);
                    this.renderProductaccess(p.status, p.detail.tag, p.url);
                  } else {
                    if (p.detail.tag === "dam" || p.detail.tag === "insar") {
                      p.tryout.link = "#/login?type=1";
                    } else {
                      p.tryout.link = "#/login?type=2";
                    }
                    p.tryout.isTarget = true;
                    p.tryout.class = "try";
                    this.productList.push(p);
                  }
                });
                return el;
              });
              this.menuList.sort((a, b) => {
                return a.id - b.id;
              });
              this.changeMenu(this.menuList[0]);
            } else {
              this.projectListbyMenu = this.productList;
            }
          })
          .catch((error) => {
            this.projectListbyMenu = this.productList;
          });
      }, 1000);
    },
    renderProductaccess(status, tag, url) {
      for (let i = 0; i < this.productList.length; i++) {
        let item = this.productList[i];
        let tag_ = item.detail.tag;
        if (tag_ === tag) {
          if (status === 1) {
            item.tryout.link = url;
            item.tryout.class = "use";
            item.tryout.label = this.$t("L_MAIN.BUTTON_0004");
          } else {
            item.tryout.link = "javascript:;";
            item.tryout.label = this.$t("L_MAIN.BUTTON_0005");
            item.tryout.class = "no";
          }
        }
      }
    },
    handleShowTip() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    linkClick(link, tag) {
      if (!this.loginStatus) {
        if (tag === "dam" || tag === "insar") {
          this.$router.push({ path: "/login?type=1" });
        } else {
          this.$router.push({ path: "/login?type=2" });
        }
      } else {
        if (
          window.localStorage.larkview_userInfo &&
          JSON.parse(window.localStorage.larkview_userInfo).value !== ""
        ) {
          window.open(link);
        } else {
          this.$router.push({ path: "/error" });
          return;
        }
      }
    },
    changeMenu(menu) {
      this.selectedMenuId = menu.icon;
      this.projectListbyMenu = menu.function;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/index/product.scss";
</style>